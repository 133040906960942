import { Notification } from 'element-ui'
import orderInfo from '@/service/orderInfo.js'
import store from '@/store/index'
import orderType from '@/utils/orderType.js'
import uniPay from '@/utils/pay.js'
import Decimal from 'decimal.js'
let orderId = ''// 订单id
let businessTableMark = ''// 餐桌名称
let businessTableId = ''// 餐桌id
let businessAreaId = ''// 餐桌区域id
let persontNum = ''// 就餐人数

const shopInfo = store.state.login.shopInfo // 商店信息
const buyCard = store.state.buyCard// 购物车
let cardLs = buyCard.card
let checkin = buyCard.checkin

async function createOrderFun () {
  // 赋值
  cardLs = buyCard.card
  checkin = buyCard.checkin

  // 获取全部勾选的口袋
  const card = cardLs.filter(item => checkin.includes(item.phone))
  console.log('card', card)

  const orderFoodDtoList = card.map(item => {
    const foodDtoList = item.ls.map(foodDto => {
      const foodProp =
        typeof foodDto.foodProp === 'string'
          ? JSON.parse(foodDto.foodProp)
          : foodDto.foodProp
      const ls = []
      for (const i in foodProp) {
        const obj = {}
        obj.name = i
        obj.specsList = [foodProp[i]]
        ls.push(obj)
      }
      return {
        foodId: foodDto.id,
        foodNum: foodDto.count,
        foodName: foodDto.foodName,
        foodOriginalPrice: foodDto.foodOriginalPrice,
        foodActualPrice: foodDto.foodConcessionalPrice,
        foodPropDtoList: ls,
        foodImgUrl: foodDto.foodImgUrl,
        foodTypeId: foodDto.foodTypeId,
        businessAreaId: foodDto.businessAreaId,
        isNew: 1
      }
    })
    return { phone: item.phone, foodDtoList }
  })

  // 获取口袋中全部的商品
  const goods = []
  console.log('orderFoodDtoList', orderFoodDtoList)
  orderFoodDtoList.forEach(item => {
    goods.push(...item.foodDtoList)
  })

  // 计算实际价格
  const actualPrice = goods.reduce((pre, item) => {
    const count = new Decimal(item.foodNum)
    const foodActualPrice = new Decimal(item.foodActualPrice).mul(new Decimal(count))
    return new Decimal(pre).add(foodActualPrice).toNumber().toFixed(2)
  }, 0)
  // 计算原来的价格
  const originalPrice = goods.reduce((pre, item) => {
    const count = new Decimal(item.foodNum)
    const foodOriginalPrice = new Decimal(item.foodOriginalPrice).mul(new Decimal(count))
    return new Decimal(pre).add(foodOriginalPrice).toNumber().toFixed(2)
  }, 0)
  const data = {
    actualPrice: actualPrice,
    businessId: shopInfo.id,
    businessTableMark: businessTableMark,
    businessTableId: businessTableId,
    businessAreaId: businessAreaId,
    orderFoodDtoList,
    repastUserPhones: checkin.join(','),
    repastUserNum: persontNum,
    originalPrice: originalPrice
  }
  const res = await orderInfo.orderInfoSave(data)
  return res.data
}

async function aggregationPay (params) {
  console.log(params)
  orderId = params.orderId
  businessTableMark = params.businessTableMark
  businessTableId = params.businessTableId
  businessAreaId = params.businessAreaId
  persontNum = params.persontNum

  try {
    if (params.createOrder) {
      orderId = await createOrderFun()
    }
    // 生成订单号
    const outTradeNo = '42007BBC' + Math.floor(Math.random() * 9999) * Date.now() + ''
    // 查询订单详情
    const res = await orderInfo.getOrderById(orderId)
    // 发起支付
    const payRes = await uniPay.pay({
      authCode: params.authCode,
      outTradeNo: outTradeNo,
      actualPrice: res.data.actualPrice,
      orderId: res.data.id
    })
    // 支付成功
    if (payRes.trade_state === 'SUCCESS') {
      // 修改支付方式
      const editOrdeRres = await orderInfo.editOrderPayType({
        id: res.data.id,
        outTradeNo: outTradeNo,
        payType: orderType(params.authCode) === 'WECHAT' ? 1 : 2
      })
      if (editOrdeRres.success) {
        // 修改订单类型
        await orderInfo.changeOrderStatus({
          id: res.data.id,
          orderStatus: params.createOrder ? 1 : 3
        })
      }
      // 下单同时结账，通知打印机
      if (params.createOrder) {
        params.createOrderCD(res.data.id)
      } else {
        params.invoicingCd()
      }
      // 加餐成功返回上一步
      if (params.modifyOrder) {
        params.modifyOrderCD()
      }
      Notification({
        title: '成功',
        message: '支付成功',
        type: 'success'
      })
      // 成功清理购物车
      store.commit('buyCard/resetCard', shopInfo.id)
    } else {
      Notification({
        title: '错误',
        message: '支付失败',
        type: 'error'
      })
      // 单点同时结账支付失败并且不是加餐的操作
      if (params.createOrder && !params.modifyOrder) {
        await orderInfo.changeOrderStatus({
          id: orderId,
          orderStatus: 4
        })
      }
      if (params.scannerVideo) {
        params.scannerVideo.cancelPay()
      }
      params.fail && params.fail()
    }
  } catch (error) {
    Notification({
      title: '错误',
      message: '支付失败',
      type: 'error'
    })
    if (params.scannerVideo) {
      params.scannerVideo.cancelPay()
    }
    // 单点同时结账支付失败并且不是加餐的操作
    if (params.createOrder && !params.modifyOrder) {
      await orderInfo.changeOrderStatus({
        id: orderId,
        orderStatus: 4
      })
    }
    params.fail && params.fail()
  }
}

export default aggregationPay
