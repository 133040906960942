<template>
  <div class="foodItem">
    <div class="foodItem__left">
      <img
        :src="imgUrl+foodItem.foodImgUrl"
        alt=""
        srcset=""
      />
      <div class="foodItem__left--data">
        <div class="foodItem__left--data__name">
          {{ foodItem.foodName }}
        </div>
        <div class="foodItem__left--data__name">
          {{ getfoodProp(foodItem.foodPropDtoList) }}
        </div>
        <div class="foodItem__left--data__name">
          ￥{{ foodItem.foodActualPrice * foodItem.foodNum }}
        </div>
      </div>
    </div>
    <div class="foodItem__right">
      {{ foodItem.foodNum }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    foodItem: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  methods: {
    getfoodProp (props) {
      if (props) {
        const Ls = props.map((item) => item.specsList[0].name)
        return Ls.join(',')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.foodItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  &__left {
    display: flex;
    align-items: center;
    img {
      width: 98px;
      height: 98px;
      border-radius: 8px;
      margin-right: 22px;
    }
    &--data {
      height: 98px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__name {
        color: #000000;
        font-weight: 400;
        font-size: 20px;
      }
    }
  }
  &__right {
    color: #000000;
    font-size: 20px;
    margin-right: 30px;
  }
}
</style>
