<template>
  <div class="orderData">
    <div class="orderData__empty" v-if="orderLs.length === 0">
      <el-empty :description="description"></el-empty>
    </div>
    <!-- 主体的开始 -->
    <div class="orderData__body">
      <el-carousel :autoplay="false" :loop="false" arrow='always'>
        <el-carousel-item v-for="orderItem in orderLs" :key="orderItem.id">
          <div class="orderData__body--item">
            <div class="orderData__body--item__head">
              <div class="orderData__body--item__head--number">
                订单编号:{{ getOrderSerialNum(orderItem.orderSerialNum) }}
              </div>
            </div>

            <!-- 食品列表 -->
            <div class="orderData__body--item__body">
              <el-scrollbar style="height: 100%">
                <div class="orderData__body--item__body--list">
                  <div v-for="lsItem in orderItem.orderFoodDtoList" :key="lsItem.phone">
                    <el-tag>用户{{lsItem.phone}}的口袋</el-tag>
                    <foodItem
                      v-for="(foodDto, index) in lsItem.foodDtoList"
                      :key="foodDto.foodId + 'newLs' + index"
                      :foodItem="foodDto"
                    />
                  </div>
                </div>
              </el-scrollbar>
            </div>
            <!-- 食品列表结束 -->
            <div class="orderData__body--item__data">
              <div class="orderData__body--item__data--item">
                <div class="orderData__body--item__data--item__left">总数</div>
                <div class="orderData__body--item__data--item__right">
                  {{ orderItem.orderFoodDtoList.length }}
                </div>
              </div>
              <div class="orderData__body--item__data--item">
                <div class="orderData__body--item__data--item__left">合计</div>
                <div class="orderData__body--item__data--item__right features">
                  ￥{{ orderItem.actualPrice }}
                </div>
              </div>
            </div>
            <!-- 账目的结束 -->
            <div class="orderData__body--item__pay">
              <div
                class="orderData__body--item__pay--tips"
                v-if="orderItem.orderStatus !== 1"
              >
                付款方式
              </div>
              <div
                class="orderData__body--item__pay--type"
                v-if="
                  (orderItem.orderStatus !== 1 && !orderSee) ||
                  orderItem.orderStatus === 0
                "
              >
                <div
                  @click="payType = 1"
                  :class="{
                    'orderData__body--item__pay--type__item': true,
                    'orderData__body--item__pay--type__active': payType === 1,
                  }"
                >
                  <i class="el-icon-full-screen"></i>
                  <span>支付码</span>
                </div>
                <div
                  @click="payType = 2"
                  :class="{
                    'orderData__body--item__pay--type__item': true,
                    'orderData__body--item__pay--type__active': payType === 2,
                  }"
                >
                  <img src="@/assets/cash.png" alt="" srcset="" />
                  <span>现金</span>
                </div>
              </div>

              <div
                class="orderData__body--item__pay--type"
                v-if="
                  orderItem.orderStatus !== 1 && orderSee && orderItem.orderStatus !== 0
                "
              >
                <div
                  v-if="orderItem.payType === 1"
                  class="orderData__body--item__pay--type__item"
                >
                  <i class="el-icon-full-screen"></i>
                  <span>支付码</span>
                </div>
                <div
                  v-if="orderItem.payType === 4"
                  class="orderData__body--item__pay--type__item"
                >
                  <img src="@/assets/cash.png" alt="" srcset="" />
                  <span>现金</span>
                </div>
              </div>
              <!-- 底部按钮 -->
              <div class="orderData__body--item__bottom">
                <div
                  v-if="orderItem.orderStatus === 1 || orderItem.orderStatus === 0"
                  class="orderData__body--item__bottom--button"
                  @click="cancelOrder(orderItem)"
                >
                  取消订单
                </div>
                <div
                  v-if="orderItem.orderStatus === 1 || orderItem.orderStatus === 0"
                  class="orderData__body--item__bottom--button"
                  @click="invoicing(orderItem)"
                >
                  {{ orderItem.orderStatus === 1 ? "清台" : "现在结账" }}
                </div>

                <div
                  v-else
                  class="orderData__body--item__bottom--button disabled"
                >
                  {{ orderStatus[orderItem.orderStatus] }}
                </div>
              </div>
            </div>
            <!-- 付款方式的结束 -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import foodItem from '@/components/foodItem/index.vue'
import orderInfo from '@/service/orderInfo.js'
import Barcode from '@/utils/barcode2.js'
import aggregationPay from '@/utils/aggregationPay.js'
export default {
  components: {
    foodItem
  },
  props: {
    // 订单数据列表
    orderLs: {
      type: Array,
      default: () => []
    },
    // 是否点击订单查看详情
    orderSee: {
      type: Boolean,
      default: false
    },
    // 数据为空时的提示语
    description: {
      type: String,
      default: '暂时没有数据,点击桌子可能有惊喜~'
    }
  },
  data () {
    return {
      payType: '',
      orderStatus: {
        0: '未支付',
        1: '已支付',
        2: '已过期',
        3: '已完成',
        4: '已取消',
        5: '已退款'
      }
    }
  },
  created () {
    console.log(this.orderLs)
  },
  methods: {
    getOrderSerialNum (orderSerialNum) {
      if (!orderSerialNum) {
        return
      }
      return orderSerialNum.substr(-3, 3)
    },
    cancelOrder (item) {
      this.$emit('cancelOrder', item)
    },
    async invoicing (item) {
      console.log(item)
      // 清台
      if (item.orderStatus === 1) {
        this.$emit('cleanMachine', item)
      } else {
        this.invoicingPay(item)
      }
    },
    // 加餐
    addFood (item) {
      this.$router.push({
        path: '/layout/shop',
        query: {
          orderId: item.id
        }
      })
    },
    async invoicingPay (item) {
      if (!this.payType) {
        this.$message({
          message: '请选择支付方式',
          type: 'warning'
        })
        return
      }
      // 微信支付
      if (this.payType === 1) {
        if (navigator.userAgent.indexOf('Html5Plus') > -1) {
          // eslint-disable-next-line no-unused-vars
          const barCodeDom = new Barcode({
            successCD: (authCode) =>
              aggregationPay({
                authCode,
                createOrder: false,
                orderId: item.id,
                invoicingCd: () => {
                   barCodeDom.closeBarcodeComponent()
                  this.$emit('invoicing', item)
                },
                fail: () => {
                  barCodeDom.closeBarcodeComponent()
                  this.$notify.error({
                    title: '错误',
                    message: '支付失败'
                  })
                }
              })
          })
        } else {
          const scannerVideo = this.$scannerVideo({
            successCD: (authCode) =>
              aggregationPay({
                authCode,
                createOrder: false,
                orderId: item.id,
                scannerVideo: scannerVideo,
                invoicingCd: () => {
                  scannerVideo.cancelPay()
                  this.$emit('invoicing', item)
                }
              })
          })
        }
      } else {
        const confirm = await this.$confirm(
          '请确保用户已经使用现金支付?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        if (confirm === 'confirm') {
          // 生成订单
          const outTradeNo = Math.floor(Math.random() * 9999) * Date.now() + ''
          const res = await orderInfo.editOrderPayType({
            id: item.id,
            outTradeNo: outTradeNo,
            payType: 4
          })
          // 修改订单支付方式成功后修改订单支付状态为已完成
          if (res.success) {
            await orderInfo.changeOrderStatus({
              id: item.id,
              orderStatus: 3
            })
            this.$emit('invoicing', item)
            this.resetCard(this.shopInfo.id)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.orderData {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  &__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__body {
    width: 100%;
    height: 100%;
    &--item {
      width: 100%;
      height: 100%;
      padding: 31px 36px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--number {
          color: #000000;
          font-size: 20px;
          margin-bottom: 22px;
        }
      }
      &__body {
        flex: 1;
        min-height: 0px;
        overflow: hidden;
        border-bottom: 1px solid #dfdfdf;
        box-sizing: border-box;
        &--list {
          overflow: hidden;
          &__tag {
            margin-bottom: 10px;
          }
        }
      }
      &__data {
        padding-top: 14px;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #dfdfdf;
        &--item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 19px;
          &__left {
            color: #000000;
            font-weight: 400;
            font-size: 20px;
          }
          &__right {
            color: #000000;
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
      &__pay {
        padding: 20px 0px;
        box-sizing: border-box;
        &--tips {
          color: #000000;
          font-weight: 400;
          font-size: 20px;
        }
        &--type {
          display: flex;
          margin-top: 20px;
          align-items: center;
          &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 68px;
            width: 144px;
            height: 52px;
            background: #f7f7fc;
            border-radius: 8px;
            box-sizing: border-box;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
              margin-right: 16px;
            }
            span {
              color: #000000;
              font-weight: 400;
              font-size: 14px;
            }
          }
          &__active {
            border: 1px solid #ffbb12;
          }
        }
      }
      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 30px;
        &--button {
          cursor: pointer;
          background: #ffbb12;
          opacity: 0.89;
          border-radius: 8px;
          width: 282px;
          height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000000;
          font-weight: 900;
          font-size: 16px;
        }
        .disabled {
          width: 100% !important;
          background: #ffbb12;
          opacity: 0.5;
        }
      }
    }
    ::v-deep .el-carousel {
      width: 100%;
      height: 100%;
    }
    ::v-deep .el-carousel__container {
      width: 100%;
      height: 100%;
    }
  }
}
::v-deep .el-carousel__indicator.is-active button {
  background: #ffbb12 !important;
}
::v-deep .el-carousel__indicator button {
  background: #d9d9d9 !important;
}
::v-deep .el-scrollbar {
  overflow-y: hidden !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto !important;
}
::v-deep .is-horizontal {
  display: none;
}
::v-deep .el-tag{
  margin-bottom: 20px;
}
.features {
  color: #f05454;
}
.el-icon-full-screen {
  margin-right: 16px;
  color: #ffbb12;
  font-size: 25px;
  font-weight: 700;
}
</style>
